<template>
  <el-dialog :visible="true" @close="$emit('close')">
    <div class="alert alert-warning">
      <NoticeIcon />
      <div>
        {{ $tc('AgreementRecording.SimilarProductPrompt.alertDescription', similarProducts.length) }}<br />
        {{ $tc('AgreementRecording.SimilarProductPrompt.alertQuestion', similarProducts.length) }}
      </div>
    </div>

    <strong>{{ $t('AgreementRecording.SimilarProductPrompt.typedTitle') }}</strong>
    <table class="table">
      <thead>
        <tr>
          <th>{{ $t('AgreementRecording.SimilarProductPrompt.sku') }}</th>
          <th>{{ $t('AgreementRecording.SimilarProductPrompt.name') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ newProduct.sku }}</td>
          <td>{{ newProduct.name }}</td>
          <td class="button-cell">
            <div class="button-wrapper">
              <Button type="secondary" @click="$emit('create', newProduct)">
                {{ $t('AgreementRecording.SimilarProductPrompt.createProduct') }}
              </Button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <strong>{{ $tc('AgreementRecording.SimilarProductPrompt.similarTitle', similarProducts.length) }}</strong>
    <table class="table">
      <thead>
        <tr>
          <th>{{ $t('AgreementRecording.SimilarProductPrompt.sku') }}</th>
          <th>{{ $t('AgreementRecording.SimilarProductPrompt.name') }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in similarProducts" :key="product.id">
          <td>{{ product.sku }}</td>
          <td>{{ product.name }}</td>
          <td class="button-cell">
            <div class="button-wrapper">
              <Button type="secondary" @click="$emit('switch', product)">
                {{ $t('AgreementRecording.SimilarProductPrompt.switchProduct') }}
              </Button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </el-dialog>
</template>

<script>
import { NoticeIcon } from '@/assets/icons';
import { Button } from '@/modules/core/components';

export default {
  components: { Button, NoticeIcon },
  props: {
    newProduct: { type: Object, required: true },
    similarProducts: { type: Array, required: true },
  },
};
</script>

<style scoped lang="scss">
.alert {
  display: flex;
  align-items: center;

  & div {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

th:first-child {
  width: 7rem;
}

.button-cell {
  padding: 0.25rem;

  .button-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>
