<template>
  <div class="price-with-percentage">
    <p>{{ formattedPrice }}</p>
    <div class="text-nowrap">
      <el-tooltip placement="top" effect="dark" :disabled="percentageToolTipText == null">
        <div slot="content" class="table-header-tooltip">
          <span>{{ percentageToolTipText }}</span>
        </div>
        <p v-if="percentage >= 1" class="percentage-text">({{ formattedPercent }})</p>
        <p v-else-if="percentage > 0" class="percentage-text text-mini" dir="ltr">
          ({{ `${LESS_THAN_LTR} ${formattedPercent}` }})
        </p>
        <p v-else class="percentage-text">
          {{ MISSING_DATA_TEXT }}
        </p>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import { useCurrency } from '@/locale/useCurrency';

import { LESS_THAN_LTR, MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import { formatPercentOrDash, formatCurrencyOrDash } from '../purchaseManagementFormatters';

export default {
  props: {
    percentage: { type: Number, default: null },
    price: { type: Number, default: null },
    percentageToolTipText: { type: String, required: false, default: null },
  },
  setup(props) {
    const { currencyFormat } = useCurrency();

    return {
      formattedPrice: computed(() => formatCurrencyOrDash(props.price, currencyFormat.value)),
      formattedPercent: computed(() => formatPercentOrDash(props.percentage >= 1 ? props.percentage : 1)),
      LESS_THAN_LTR,
      MISSING_DATA_TEXT,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
.price-with-percentage {
  display: flex;
  gap: 0.25rem;

  .percentage-text {
    color: $typography-secondary;
  }
}
</style>
