<template>
  <div class="search-input" :dir="$direction">
    <el-input
      ref="searchBarRef"
      v-model="searchText"
      size="small"
      clearable
      :placeholder="inputPlaceholder"
      @input="(value) => $emit('on-supplier-text-change', value)"
    >
      <template #prefix>
        <div class="search-icon">
          <SearchIcon width="16px" height="16px" />
        </div>
      </template>
    </el-input>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { SearchIcon } from '@/assets/icons';

export default {
  name: 'SearchBar',
  components: { SearchIcon },
  props: {
    searchTextRef: { type: String, required: true, default: '' },
    inputPlaceholder: { type: String, required: true, default: '' },
    focusOnMount: { type: Boolean, default: false },
  },
  emits: ['on-supplier-text-change'],
  setup(props) {
    const searchText = ref(props.searchTextRef);
    const searchBarRef = ref(null);

    onMounted(() => {
      if (props.focusOnMount) {
        searchBarRef.value.focus();
      }
    });

    return {
      searchText,
      searchBarRef,
    };
  },
};
</script>
<style scoped lang="scss">
.search-input {
  width: 300px;
  min-width: 184px;
  .search-icon {
    display: flex;
    align-items: center;
    height: 100%;
    width: 16px;
  }
}
</style>
