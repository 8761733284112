<template>
  <div class="column-settings">
    <div class="preset-dropdown">
      <el-select v-model="selectedPreset" size="small" :style="{ width: '100%' }" @change="onPresetSelected">
        <el-option
          v-for="(preset, index) in presetOptions"
          :key="`${preset}${index}`"
          :label="
            $t(
              `terms.supplierTerms.supplierTermTable.settings.${preset}.${
                preset === PRESETS.default ? 'newLabel' : 'label'
              }`
            )
          "
          :value="preset"
          :disabled="preset === PRESETS.custom"
        />
      </el-select>
    </div>
    <div class="divider"></div>
    <div class="columns">
      <div v-for="(category, index) in categories" :key="`${category}${index}`" class="category">
        <div class="category-checkbox">
          <el-checkbox
            v-model="categoriesAllCheckedOrIndeterminate[category].checkAll"
            :indeterminate="categoriesAllCheckedOrIndeterminate[category].indeterminate"
            @change="(checked) => handleCheckAllChange(checked, category)"
          />
          <p class="category-checkbox-text">
            {{ $t(`terms.supplierTerms.supplierTermTable.settings.categories.${category}`) }}
          </p>
        </div>
        <div class="category-columns">
          <div class="horizontal-divider"></div>
          <div class="category-columns-checkboxes">
            <div
              v-for="(column, colIndex) in getAllColumnsThatMatchCategory(category)"
              :key="`${column.key}${colIndex}`"
              class="column-checkbox"
            >
              <el-checkbox
                v-model="columnsCheckedByKey[column.key]"
                :disabled="column.disabled"
                @change="(checked) => handleColumnsChanged(checked, column)"
              />
              <p class="column-checkbox-text" :class="{ disabledColor: column.disabled }">{{ column.header }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="comparison">
        <p class="comparison-title">{{ $t('terms.supplierTerms.supplierTermTable.settings.comparison.label') }}</p>
        <div class="comparison-choices">
          <el-radio
            v-for="(comparisonModeTranslation, index) in comparisonModeTranslations"
            :key="`comparisonMode${index}`"
            v-model="comparisonMode"
            :label="index + 1"
            @change="comparisonModeChanged"
          >
            {{ comparisonModeTranslation }}
          </el-radio>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="actions">
      <Button type="secondary" @click="cancelChanges">{{ $t('cancel') }}</Button>
      <Button type="primary" @click="applyChanges">{{ $t('apply') }}</Button>
    </div>
  </div>
</template>
<script>
import { clone } from 'ramda';
import { computed, ref, getCurrentInstance, watch } from 'vue';

import { PRESETS } from '@/modules/purchase-management/tools/constants';

import { Button } from '@/modules/core/components';

export default {
  components: { Button },
  props: {
    tableColumns: { type: Array, required: true },
    popoverVisible: { type: Boolean, required: true },
    presetsToIgnore: { type: Array, default: () => [] },
    onPresetChanged: { type: Function, required: true },
    selectedComparisonMode: { type: Number, default: 1 },
  },
  emits: ['on-apply', 'on-cancel'],
  setup(props, { emit }) {
    const { $i18n } = getCurrentInstance().proxy;
    const selectedPreset = ref(PRESETS.default);
    const unCommittedColumns = ref(null); // This is used to update and have a state for the checkboxes
    const comparisonMode = ref(props.selectedComparisonMode);

    watch(
      () => props.selectedComparisonMode,
      (value) => (comparisonMode.value = value)
    );

    const columnsForCheckboxes = computed(() => {
      // This is used mainly to sync the initial table columns
      if (unCommittedColumns.value && props.popoverVisible) return unCommittedColumns.value;

      const originalColumns = props.tableColumns;
      if (!originalColumns || originalColumns.length === 0) return [];
      const cols = originalColumns.map((column) => {
        let checked = false;
        let disabled = false;
        if (column.editable === false) {
          checked = true;
          disabled = true;
        } else if (column.editable === true && column.hidden === false) {
          checked = true;
        }
        return {
          ...column,
          checked,
          disabled,
        };
      });

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      unCommittedColumns.value = cols;
      return cols;
    });

    if (comparisonMode.value > 1) {
      applyChanges();
    }

    const categories = computed(() => {
      const categoriesSet = new Set();
      columnsForCheckboxes.value.forEach(({ category }) => categoriesSet.add(category));
      return [...categoriesSet];
    });

    const getAllColumnsThatMatchCategory = (categoryToRetrieve) => {
      return columnsForCheckboxes.value.filter(({ category }) => categoryToRetrieve === category);
    };

    const isCategoryIndeterminate = (category) => {
      const columnsThatMatchCategory = getAllColumnsThatMatchCategory(category);
      const initialFlag = columnsThatMatchCategory[0].checked;

      return columnsThatMatchCategory.some(({ checked }) => checked !== initialFlag);
    };

    const categoriesAllCheckedOrIndeterminate = computed(() => {
      const categoryMap = {};
      categories.value.forEach((category) => {
        categoryMap[category] = {
          checkAll: isCategoryAllChecked(category),
          indeterminate: isCategoryIndeterminate(category),
        };
      });

      return categoryMap;
    });

    const columnsCheckedByKey = computed(() => {
      const columnsMap = {};
      columnsForCheckboxes.value.forEach(({ key, checked }) => {
        columnsMap[key] = checked;
      });

      return columnsMap;
    });

    const isCategoryAllChecked = (category) => {
      const columns = getAllColumnsThatMatchCategory(category);
      return columns[0].checked === true;
    };

    const handleCheckAllChange = (checked, categoryToUpdate) => {
      const currentColumns = columnsForCheckboxes.value;
      const clonedColumns = clone(currentColumns);
      currentColumns.forEach((column) => {
        if (column.category === categoryToUpdate && column.disabled === false) {
          const colIndex = clonedColumns.findIndex(({ key }) => column.key === key);
          clonedColumns[colIndex] = { ...column, checked, hidden: !checked };
        }
      });
      unCommittedColumns.value = clonedColumns;
      selectedPreset.value = PRESETS.custom;
    };

    const handleColumnsChanged = (checked, column) => {
      const currentColumns = columnsForCheckboxes.value;
      const clonedColumns = clone(currentColumns);
      const colIndex = clonedColumns.findIndex(({ key }) => column.key === key);
      clonedColumns[colIndex] = { ...column, checked, hidden: !checked };
      unCommittedColumns.value = clonedColumns;
      selectedPreset.value = PRESETS.custom;
    };

    function applyChanges() {
      emit('on-apply', {
        columnsToUpdate: columnsForCheckboxes.value,
        amountOfComparisons: comparisonMode.value,
        preset: selectedPreset.value,
      });
    }

    const cancelChanges = () => {
      emit('on-cancel');
    };

    const onPresetSelected = (preset) => {
      const { comparisonMode: updatedComparisonMode, updatedColumns } = props.onPresetChanged(
        [...unCommittedColumns.value],
        preset
      );

      comparisonMode.value = updatedComparisonMode;
      unCommittedColumns.value = updatedColumns;
    };

    return {
      PRESETS,
      categories,
      selectedPreset,
      columnsCheckedByKey,
      comparisonMode,
      comparisonModeTranslations: [
        $i18n.t('terms.supplierTerms.supplierTermTable.settings.comparisonModes.none'),
        $i18n.t('terms.supplierTerms.supplierTermTable.settings.comparisonModes.two'),
        $i18n.t('terms.supplierTerms.supplierTermTable.settings.comparisonModes.three'),
      ],
      presetOptions: Object.keys(PRESETS).filter((preset) => !props.presetsToIgnore.includes(preset)),
      categoriesAllCheckedOrIndeterminate,
      applyChanges,
      cancelChanges,
      comparisonModeChanged: () => (selectedPreset.value = PRESETS.custom),
      onPresetSelected,
      handleCheckAllChange,
      handleColumnsChanged,
      getAllColumnsThatMatchCategory,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep {
  .el-radio__input.is-checked + .el-radio__label {
    color: black !important;
  }
  .el-select-dropdown__item + &.is-disabled {
    display: none;
  }
}
.column-settings {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 18.5625rem;
  height: 21.8125rem;
  .divider {
    border: 0.5px solid #d9dcde;
  }
  .horizontal-divider {
    border: 1px solid #d9dcde;
  }
  .columns {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: auto;

    .category {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .category-columns {
        display: flex;
        gap: 1rem;
        margin-inline-start: 5px;
        .category-columns-checkboxes {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          color: #1d1e20;
          .column-checkbox {
            display: flex;
            gap: 0.5rem;
            .column-checkbox-text {
              width: 220px;
              word-break: break-word;
            }
          }
        }
      }
      .category-checkbox {
        display: flex;
        gap: 0.5rem;
        color: #1d1e20;
        .category-checkbox-text {
          font-weight: 500;
        }
      }
    }
  }
  .actions {
    display: flex;
    align-self: flex-end;
  }
}
.disabledColor {
  color: #c4c6cf;
}

.comparison {
  color: #1d1e20;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .comparison-title {
    font-weight: 500;
  }
  .comparison-choices {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>
